import { fieldValidation } from "@lib/shared/contactUsForm";
import { ContactUsField } from "@lib/shared/types";
import { FormEvent, useReducer } from "react";
import { submitContactUsForm, submitDoNotSellForm } from "src/api";
import { handleFieldMasking } from "@lib/shared/form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getFinalContactUsFields } from "@lib/contactUsUtils";
import { useDomainContext } from "./useDomainContext";
type FormReducerAction =
    | {
          type: ActionTypes.SET_FORM_ERROR;
          payload: string | null;
      }
    | { type: ActionTypes.SET_FORM_SUBMITTED; payload: undefined }
    | { type: ActionTypes.SET_FORM_ERROR; payload: string | null }
    | {
          type: ActionTypes.SET_FIELDS_STATE;
          payload: {
              [x: string]: {
                  value: string;
                  valid: boolean;
                  errorMessage: string;
              };
          } | null;
      }
    | { type: ActionTypes.SET_LOADING_FORM; payload: boolean };

enum ActionTypes {
    SET_FIELDS_STATE = "SET_FIELDS_STATE",
    SET_LOADING_FORM = "SET_LOADING_FORM",
    SET_FORM_SUBMITTED = "SET_FORM_SUBMITTED",
    SET_FORM_ERROR = "SET_FORM_ERROR",
}

type FieldsState = {
    [x: string]: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
} | null;

interface InitState {
    submitted: boolean;
    loadingForm: boolean;
    fieldsState: FieldsState;
    formError: string | null;
}

const initState: InitState = {
    submitted: false,
    loadingForm: false,
    fieldsState: null,
    formError: null,
};

export const formReducer = (state: InitState, action: FormReducerAction) => {
    switch (action.type) {
        case ActionTypes.SET_FIELDS_STATE:
            return {
                ...state,
                fieldsState: {
                    ...state.fieldsState,
                    ...action.payload,
                },
            };
        case ActionTypes.SET_LOADING_FORM:
            return {
                ...state,
                loadingForm: action.payload,
            };
        case ActionTypes.SET_FORM_SUBMITTED:
            return {
                ...state,
                submitted: true,
            };
        case ActionTypes.SET_FORM_ERROR:
            return {
                ...state,
                formError: action.payload,
            };

        default:
            return state;
    }
};

export const useContactUsForm = (
    formFields: ContactUsField[],
    severalBrandsRecaptcha?: boolean,
    setSubmittedForm?: React.Dispatch<React.SetStateAction<boolean>>,
    doNotSellForm?: boolean,
    type?: string,
    category?: string,
) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { visitDetails, domain } = useDomainContext();

    const { fields } = useFields(formFields);
    const [state, dispatch] = useReducer(formReducer, {
        ...initState,
        fieldsState: fields,
    });

    const { submitted, loadingForm, fieldsState, formError } = state;

    const handleFieldValidation = async (
        field: ContactUsField,
        dispatchState?: boolean,
    ) => {
        const { valid, message } = await fieldValidation(
            field,
            fieldsState![field.name].value,
        );

        const newObj: {
            [x: string]: {
                valid: boolean;
                errorMessage: string;
                value: string;
            };
        } = {};

        newObj[field.name] = {
            ...fieldsState![field.name],
            valid,
            errorMessage: message,
        };

        if (dispatchState) {
            dispatch({
                type: ActionTypes.SET_FIELDS_STATE,
                payload: { ...newObj },
            });
        }

        return {
            name: field.name,
            valid: valid,
            errorMessage: message,
            value: fieldsState![field.name].value,
        };
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (loadingForm || submitted) return;
        dispatch({
            type: ActionTypes.SET_LOADING_FORM,
            payload: true,
        });
        const formFieldValidation = [];
        for (const promise of formFields) {
            const result = await handleFieldValidation(promise, false);
            formFieldValidation.push(result);
        }

        const finalObj = formFieldValidation.reduce(
            (obj: FieldsState, currentValue) => {
                obj![currentValue.name] = {
                    valid: currentValue.valid,
                    errorMessage: currentValue.errorMessage,
                    value: currentValue.value,
                };
                return obj;
            },
            {},
        );
        dispatch({
            type: ActionTypes.SET_FIELDS_STATE,
            payload: { ...finalObj },
        });
        const formValid = !formFieldValidation
            .map((filed) => filed.valid)
            .includes(false);

        if (formValid) {
            const defaultFormFields: { [x: string]: string } = {};
            const extraFields: { key: string; value: string }[] = [];

            if (category) {
                extraFields.push({
                    key: "category",
                    value: category,
                });
            }

            formFields.forEach((field) => {
                if (field.isExtraField) {
                    extraFields.push({
                        key: field.name,
                        value: fieldsState![field.name].value,
                    });
                } else {
                    if (field.name === "name") {
                        const name = fieldsState![field.name].value.split(" ");
                        defaultFormFields["firstName"] = name[0];
                        defaultFormFields["lastName"] = name[1] ?? "---";
                    }
                    defaultFormFields[field.name] =
                        fieldsState![field.name].value;
                }
            });

            dispatch({
                type: ActionTypes.SET_FORM_ERROR,
                payload: null,
            });

            const token =
                executeRecaptcha && severalBrandsRecaptcha
                    ? await executeRecaptcha("contact_us_form")
                    : null;

            const finalData = getFinalContactUsFields({
                severalBrandsRecaptcha,
                defaultFormFields,
                extraFields,
                token,
                doNotSellForm,
                visitId: visitDetails?.visitId,
                domainId: domain?.id,
            });

            let resData = null;
            let resError = null;
            if (doNotSellForm) {
                const { data, error } = await submitDoNotSellForm({
                    //@ts-ignore
                    fields: { ...finalData },
                });
                resData = data;
                resError = error;
            } else {
                const { data, error } = await submitContactUsForm({
                    //@ts-ignore
                    fields: { ...finalData },
                    type,
                });
                resData = data;
                resError = error;
            }

            dispatch({
                type: ActionTypes.SET_LOADING_FORM,
                payload: false,
            });

            if (resError) {
                if (
                    resData &&
                    resData?.code === "INVALID_PHONE_NUMBER_FORMAT"
                ) {
                    dispatch({
                        type: ActionTypes.SET_FORM_ERROR,
                        payload: "Invalid phone number",
                    });
                } else {
                    dispatch({
                        type: ActionTypes.SET_FORM_ERROR,
                        payload:
                            "An error has occurred, please try again later",
                    });
                }
            } else {
                if (setSubmittedForm) {
                    setSubmittedForm(true);
                }
                dispatch({
                    type: ActionTypes.SET_FORM_SUBMITTED,
                    payload: undefined,
                });
            }
        } else {
            dispatch({
                type: ActionTypes.SET_LOADING_FORM,
                payload: false,
            });
        }
    };

    const handleFieldBlur = async (field: ContactUsField) => {
        await handleFieldValidation(field, true);
    };

    const handleFieldChange = (field: ContactUsField, val: string) => {
        const newVal =
            field.validation.rules?.type === "isPhone" && val
                ? handleFieldMasking("us-phone", val)
                : val;

        const newObj: {
            [x: string]: {
                valid: boolean;
                errorMessage: string;
                value: string;
            };
        } = {};

        newObj[field.name] = {
            valid: true,
            errorMessage: "",
            value: newVal,
        };

        dispatch({
            type: ActionTypes.SET_FIELDS_STATE,
            payload: { ...newObj },
        });
    };

    return {
        handleSubmit,
        handleFieldBlur,
        handleFieldChange,
        submitted,
        loadingForm,
        formError,
        fieldsState,
    };
};

const useFields = (
    fields: ContactUsField[],
): {
    fields: {
        [x: string]: {
            value: string;
            valid: boolean;
            errorMessage: string;
        };
    };
} => {
    const temp: {
        [x: string]: {
            value: string;
            valid: boolean;
            errorMessage: string;
        };
    } = {};

    fields.forEach((field) => {
        temp[field.name] = {
            value: field.defaultValue ?? "",
            valid: true,
            errorMessage: "",
        };
    });

    return { fields: temp };
};
