export const getFinalContactUsFields = ({
    severalBrandsRecaptcha,
    defaultFormFields,
    extraFields,
    token,
    doNotSellForm,
    visitId,
    domainId,
}: {
    severalBrandsRecaptcha?: boolean;
    defaultFormFields: { [x: string]: string };
    extraFields: { key: string; value: string }[];
    token: string | null;
    doNotSellForm?: boolean;
    visitId?: string;
    domainId?: number;
}) => {
    if (doNotSellForm) {
        return {
            ...defaultFormFields,
            name: undefined,
            domainId,
            visitId,
            deleteMyInfo: !!defaultFormFields.deleteMyInfo,
            doNotSell: !!defaultFormFields.doNotSell,
        };
    }
    return severalBrandsRecaptcha
        ? {
              ...defaultFormFields,
              name: undefined,
              customFields: extraFields,
              googleReCaptcha: token,
          }
        : { ...defaultFormFields, customFields: extraFields };
};
